import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { colors, device } from '../styles/config'
import ArrowSvg from '../images/arrow-right.svg'

const ButtonContainer = styled.div`
  width: 270px;
  font-size: 0.8125rem;
  letter-spacing: 3.03px;
  font-weight: 700;
  padding: 1rem 2rem;
  text-transform: uppercase;
  background-color: ${ ({ light }) => light ? `white` : colors.secondary };
  color: ${ props => props.light ? colors.primary : `white` };
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  box-shadow: 20px 2px 44px 0 rgba(208,208,215, 0.61);
  transition: color 0.3s, background-color 0.3s;
  
  ${ props => props.submitDisabled && css`
    background-color: #D0D0D7;
  ` }

  &:focus {
    outline: 0;
  }

  svg {
    transition: transform 0.3s;
    transform: translate3d(0,0,0);
    
    path {
      fill: ${ props => props.light ? colors.secondary : 'white' };
    }
  }

  @media ${ device.hover } {
    &:hover {
      color: ${ props => props.light ? colors.secondary : `white` };
      background-color: ${ ({ light }) => light ? `white` : colors.secondaryDarkened };

      svg {
        transform: translate3d(10px,0,0);
      }
    }
  }
`

const Button = ({ light, children, className, onClick, type, submitDisabled }) => {
  return (
    <>
      {type !== 'submit' &&
        <ButtonContainer
          light={light}
          className={className}
          onClick={() => onClick && onClick()}
        >
          {children}
          <ArrowSvg/>
        </ButtonContainer>
      }
      {type === 'submit' &&
        <ButtonContainer
          as="button"
          type="submit"
          light={light}
          className={className}
          onClick={() => onClick && onClick()}
          submitDisabled={submitDisabled}
        >
          {children}
          <ArrowSvg/>
        </ButtonContainer>
      }
    </>
  )
}
Button.propTypes = {
  children: PropTypes.node.isRequired,
  light: PropTypes.bool,
  className: PropTypes.string,
}

Button.defaultProps = {
  light: false
}

export default Button
