import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import GatsbyImage from 'gatsby-image'

import Background from '../components/Background'
import Social from '../components/Social'
import Button from '../components/Button'
import Carousel from '../components/Carousel'
import { colors, device } from '../styles/config'

const Container = styled.div`
  ${ props => props.theme.wrapper }

  @media ${ device.sm } {
    ${ props => props.theme.paddingTop }
    padding-bottom: 3.125rem;
  }

  h4 {
    &:before {
      display: block;
      content: '';
      margin-bottom: 20px;
      width: 55px;
      height: 5px;
      background-color: ${ colors.highlight };

      @media ${ device.sm } {
        margin-bottom: 10px;
      }
    }

    @media ${ device.sm } {
      margin-bottom: 3.125rem;
    }
  }
`

const CarouselContainer = styled(Container)`
  padding-right: 0;
`

const TextContainer = styled.div`
  padding-right: 7.1875rem;

  @media ${ device.lg } {
    padding-right: 0;
  }

  @media ${ device.sm } {
    padding: 0;
    margin-bottom: 2.5rem;
  }
`

const Header = styled.h1`
  margin-top: 6.25rem;
  padding-right: 7.1875rem;

  @media ${ device.lg } {
    padding-right: 0;
  }

  @media ${ device.sm } {
    padding-right: 0;
    margin-top: 0;
  }
`

const Image = styled(GatsbyImage)`
  width: 100%;
`

const MenuContainer = styled(Row)`
  margin-bottom: 6rem;
`

const MenuName = styled.h2`
  text-align: right;

  @media ${ device.sm } {
    text-align: left;
    /* margin-top: 3rem; */
    margin-bottom: 3rem;
  }
`

const MenuButton = styled(Button)`
  margin-top: 3rem;

  @media ${ device.sm } {
    width: 100%;
  }
`

const MenuNameContainer = styled(Col)`
  padding-left: 0;

  @media ${ device.sm } {
    padding-left: 0.9375rem;
  }
`

const GastroPage = ({ data: { datoCmsGastro: { header, text, slideshow, menus } } }) => {
  return (

    <>
      <CarouselContainer>
        <Row>
          <Col sm={5} xs={12}>
            <Header>{header}</Header>
            <TextContainer dangerouslySetInnerHTML={{ __html: text }}/>
          </Col>
          <Col sm={7} xs={12}>
            <Carousel withControls>
              {slideshow.map((image, index) => (
              // <div key={index}>
                <Image key={index} fluid={image.fluid}/>
              // </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      </CarouselContainer>
      <Background>
        <Container>
          {menus.map(({ name, description, menuPdf }, index) => (
            <MenuContainer key={index}>
              {index === 0 &&
                  <Col xs={12} sm={3}>
                    <h4>Speisekarte</h4>
                  </Col>
              }
              <MenuNameContainer xs={12} sm={3} lg={2} smOffset={index === 0 ? 0 : 3}>
                <MenuName>{name}</MenuName>
              </MenuNameContainer>
              <Col xs={12} sm={6} md={5}>
                <div dangerouslySetInnerHTML={{ __html: description }}/>
                {menuPdf &&
                  <a href={menuPdf.url}>
                    <MenuButton light>
                      {menuPdf.title}
                    </MenuButton>
                  </a>
                }
              </Col>
            </MenuContainer>
          ))}
          <Social/>
        </Container>
      </Background>
    </>

  )
}

// ad back in
// downloadedFile {
//   publicURL
// }

export const query = graphql`
  query GastroPage {
    datoCmsGastro {
      header
      text
      menus {
        name
        description
        menuPdf {
          title
          url
        }
      }
      slideshow {
        fluid(maxWidth: 600, imgixParams: {
          w: "600",
          h: "390",
          fm: "jpg",
          auto: "compress,format",
          fit: "crop",
          crop: "edges"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
  }
`

export default GastroPage
